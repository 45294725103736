<template>
  <div class="container u-flex-col u-col-center" v-cloak>
    <template v-if="applyDatas">
      <div class="top-con pr">
        <div class="bg-div pa" />
        <div class="u-flex-col u-col-center pr">
          <h5>总待还款金额</h5>
          <h2>{{ applyDatas.totalAmount }}</h2>
          <p>
            {{
              `${applyDatas.repayTypeTxt} 总利息${applyDatas.totalInterest}元`
            }}
          </p>
          <span @click="handleMakeCall">{{ `客服电话：${phoneNumber}` }}</span>
        </div>
      </div>

      <!-- 分期详情列表 -->
      <div class="bottom-con">
        <van-cell-group inset v-for="(i, idx) in applyDatas.plans" :key="idx">
          <van-cell
            :title="i.principal"
            :value="`含利息${i.interest}`"
            :border="false"
          >
            <p slot="label">
              {{ i.repayDate }}
              <van-tag type="primary">{{ `${i.periodTxt}` }}</van-tag>
            </p>
          </van-cell>
        </van-cell-group>
      </div>
    </template>
  </div>
</template>
<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "RepaymentPlan",
  data() {
    return {
      client: this.MixinClientBrowser(), // 设备
      phoneNumber: "95713",
      applyId: this.$route.query.applyId, // 计划ID
      applyDatas: null, // 还款计划集合
    };
  },
  mounted() {
    this.getApplyData();
  },
  methods: {
    // 获取还款计划信息
    getApplyData() {
      API_Common.getApplyData(this.applyId).then((res) => {
        if (res.code === 200) {
          this.applyDatas = res.data;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    // 调用客户端拨打电话功能
    handleMakeCall() {
      const { client, phoneNumber } = this;
      if (client === "ios") {
        try {
          this.$bridge.callhandler("go2CallPhoneNum", { phoneNumber });
        } catch (err) {
          console.log("try---catch-调用IOS客户端方法报错");
        }
      } else if (client === "android") {
        if (window.LoanMarketChina) {
          try {
            window.LoanMarketChina.go2CallPhoneNum(phoneNumber);
          } catch (err) {
            console.log("try---catch-调用安卓客户端方法报错");
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #f2f5fa;
  padding-top: 0.5rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .bottom-con,
  .top-con {
    width: calc(100% - 0.8rem);
    margin: 0;
  }

  .top-con {
    div.bg-div {
      width: 132%;
      height: 4.46rem;
      background: #3563fa;
      border-radius: 50%;
      left: -16%;
      top: -1.9rem;
    }

    div.pr {
      padding: 0.48rem 0 0.4rem;
      background: #ffffff;
      border-radius: 0.16rem;
      box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);
      margin: 0 0 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #868e9e;
      font-weight: normal;
      font-size: 0.24rem;

      > h5 {
        font-size: 0.26rem;
        font-weight: normal;
      }

      > h2 {
        font-size: 0.72rem;
        font-family: YOUSHEhaoshenti, YOUSHEhaoshenti-Regular;
        font-weight: normal;
        color: #111c31;
        margin: 0.16rem 0;
      }

      > span {
        font-size: 0.24rem;
        color: #3563fa;
        margin-top: 0.24rem;
      }
    }
  }

  .bottom-con {
    .van-cell-group--inset {
      margin: 0 0 0.32rem;
    }

    .van-cell__title {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: normal;
      color: #111c31;
    }

    .van-cell__label,
    .van-cell__value {
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #868e9e;
    }

    .van-hairline--top-bottom::after {
      display: none;
    }

    .period-title {
      color: #3563fa;
    }
  }
}
</style>
